import Carousel from '../node_modules/bootstrap/js/src/carousel.js';
import Zooming from 'zooming';

function collapseList () {
	var items = document.querySelectorAll('.collapses > dt');

	items.forEach(function (item) {
		item.addEventListener('click', function () {
			item.classList.toggle('active');
		});
	});
}

function toggleNav () {
	const toggler = document.querySelector('.page-header .btn-nav');

	toggler.addEventListener('click', e => {
		toggler.classList.toggle('active');
	});

	document.addEventListener('click', e => {
		if (e.target !== toggler && toggler.classList.contains('active')) {
			setTimeout(() => {
				toggler.classList.remove('active');
			}, 50);
		}
	});
}

new Carousel('#reviewsCarousel');
collapseList();
toggleNav();

document.addEventListener('DOMContentLoaded', function () {
	new Zooming({
		transitionDuration: 0.01,
		bgOpacity: 0.6
	}).listen('.zoomable')
});